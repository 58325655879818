"use client";

import { FC } from "react";
import Image from "next/image";
import engine from "./../../../../public/images/engine.png";
import { GrValidate } from "react-icons/gr";

interface TEngineRenderProps {}

const EngineRender: FC<TEngineRenderProps> = (props: TEngineRenderProps) => {
  return (
    <div className='flex items-center justify-between w-full h-full p-1 md:p-10 rounded-md bg-gradient-to-t from-primary/5'>
      <div>
        <ul>
          <li className='flex items-center gap-2 px-1'>
            <GrValidate className='text-primary' />
            <p>Acquérir plus de couple moteur.</p>
          </li>
          <li className='flex items-center gap-2 px-1'>
            <GrValidate className='text-primary' />
            <p>Gain de puissance pour les dépassement et les cotes.</p>
          </li>
          <li className='flex items-center gap-2 px-1'>
            <GrValidate className='text-primary' />
            <p>Plus de latence du Turbo.</p>
          </li>
          <li className='flex items-center gap-2 px-1'>
            <GrValidate className='text-primary' />
            <p>Réduction de la consommation.</p>
          </li>
          <li className='flex items-center gap-2 px-1'>
            <GrValidate className='text-primary' />
            <p>Respect des normes antipollution.</p>
          </li>
          <li className='flex items-center gap-2 px-1'>
            <GrValidate className='text-primary' />
            <p>Respect des tolérances constructeurs.</p>
          </li>
        </ul>
      </div>

      <div className='flex items-center h-[20px] w-[100px] md:h-[50px] md:w-[250px]'>
        <Image src={engine} alt={"Engine"} />
      </div>
    </div>
  );
};

export default EngineRender;
