"use client";

import { FC, useEffect, useRef, useState } from "react";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";

const dataSets = [
  [
    { rpm: 1000, couple: 100, puissance: 70 },
    { rpm: 2000, couple: 200, puissance: 140 },
    { rpm: 3000, couple: 300, puissance: 210 },
    { rpm: 4000, couple: 350, puissance: 245 },
    { rpm: 5000, couple: 400, puissance: 280 },
    { rpm: 6000, couple: 360, puissance: 270 },
    { rpm: 7000, couple: 320, puissance: 240 },
    { rpm: 8000, couple: 280, puissance: 210 },
  ],
  [
    { rpm: 1000, couple: 120, puissance: 80 },
    { rpm: 2000, couple: 240, puissance: 160 },
    { rpm: 3000, couple: 350, puissance: 250 },
    { rpm: 4000, couple: 400, puissance: 280 },
    { rpm: 5000, couple: 450, puissance: 300 },
    { rpm: 6000, couple: 410, puissance: 290 },
    { rpm: 7000, couple: 370, puissance: 260 },
    { rpm: 8000, couple: 330, puissance: 230 },
  ],
  [
    { rpm: 1000, couple: 130, puissance: 90 },
    { rpm: 2000, couple: 260, puissance: 180 },
    { rpm: 3000, couple: 370, puissance: 270 },
    { rpm: 4000, couple: 420, puissance: 290 },
    { rpm: 5000, couple: 470, puissance: 310 },
    { rpm: 6000, couple: 430, puissance: 300 },
    { rpm: 7000, couple: 390, puissance: 280 },
    { rpm: 8000, couple: 350, puissance: 250 },
  ],
];

type TReprogrammationLineChartProps = {};

const ReprogrammationLineChart: FC<TReprogrammationLineChartProps> = (
  props: TReprogrammationLineChartProps,
) => {
  const [containerSize, setContainerSize] = useState({ width: 700, height: 300 });
  const [dataIndex, setDataIndex] = useState(0); // Index pour gérer l'ensemble de données actif
  const [key, setKey] = useState(0); // Clé pour forcer le re-rendu
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setDataIndex((prevIndex) => (prevIndex + 1) % dataSets.length); // Passer à l'ensemble de données suivant
      setKey((prevKey) => prevKey + 1); // Change la clé pour forcer le re-rendu
    }, 10000); // Changer toutes les 10 secondes

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const currentRef = containerRef.current;

      if (currentRef) {
        const observer = new ResizeObserver((entries) => {
          if (entries[0].contentRect) {
            setContainerSize({
              width: entries[0].contentRect.width,
              height: entries[0].contentRect.height,
            });
          }
        });

        observer.observe(currentRef);

        return () => {
          observer.unobserve(currentRef);
        };
      }
    }
  }, []);

  return (
    <div ref={containerRef} className='w-full h-20 md:h-64'>
      <ResponsiveContainer width='100%' height='100%'>
        <LineChart
          key={key} // Utilise une clé dynamique pour forcer le re-rendu
          data={dataSets[dataIndex]} // Affiche l'ensemble de données actif
          width={containerSize.width}
          height={containerSize.height}
          margin={{
            top: 5,
            right: 5,
            left: 5,
            bottom: 5,
          }}
        >
          <CartesianGrid className='stroke-gray-600/25' />
          <XAxis dataKey='rpm' className='text-xs text-gray-600' />
          <YAxis yAxisId='left' className='text-xs text-gray-600' />
          <YAxis yAxisId='right' orientation='right' className='text-xs text-gray-600' />
          <Line
            yAxisId='left'
            type='monotone'
            dataKey='couple'
            stroke='#098aea'
            activeDot={{ r: 6 }}
            isAnimationActive={false} // Désactive l'animation côté serveur
            animationDuration={2000}
            animationBegin={0}
            className='stroke-current'
          />
          <Line
            yAxisId='right'
            type='monotone'
            dataKey='puissance'
            stroke='#99cc00'
            isAnimationActive={false} // Désactive l'animation côté serveur
            animationDuration={2000}
            animationBegin={0}
            className='stroke-current'
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ReprogrammationLineChart;
