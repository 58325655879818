import { FC } from "react";

const ModulePresentation: FC = () => {
  return (
    <div className='w-full h-full py-5 px-10 flex flex-col items-center'>
      <h2 className='text-center text-2xl mb-2'>RsTronic Martinique</h2>
      <a className='text-2xl text-primary' href='tel:+596696505653'>
        06 96 50 56 53
      </a>
    </div>
  );
};

export default ModulePresentation;
