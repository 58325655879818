import { FC } from "react";

interface TTextPrimaryProps {
  text: string;
}

const TextPrimary: FC<TTextPrimaryProps> = ({ text }: TTextPrimaryProps) => {
  return <span className='text-primary'>{text}</span>;
};

export default TextPrimary;
