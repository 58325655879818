"use client";

import { FC, ReactNode } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/shadcn/ui/accordion";
import Image, { StaticImageData } from "next/image";

interface TOffersInformationProps {
  title: string;
  text: ReactNode;
  image: StaticImageData;
  AccordionItemValue: string;
}

const AccordionOfferInformation: FC<TOffersInformationProps> = ({
  title,
  text,
  image,
  AccordionItemValue,
}: TOffersInformationProps) => {
  return (
    <div className='flex bg-gradient-to-br from-primary/5 border border-primary/40 p-0 rounded-md shadow-md'>
      <Accordion type='single' collapsible className='flex-1'>
        <AccordionItem value={AccordionItemValue}>
          <AccordionTrigger className='bg-gradient-to-br from-primary/5 border border-b-0 border-primary/5 p-1 md:p-2 shadow-md uppercase'>
            <header className='flex-1 flex items-center justify-between'>
              <div>
                <h3>{title}</h3>
              </div>
              <div className='flex justify-center'>
                <Image
                  src={image}
                  alt={"Processus de Lecture"}
                  className='object-cover'
                  height={30}
                />
              </div>
            </header>
          </AccordionTrigger>
          <AccordionContent className='p-2'>
            <div>
              <h4>Détails</h4>
              <p className='text-justify'>{text}</p>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default AccordionOfferInformation;
