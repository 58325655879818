"use client";

import SocialNetwork from "@/components/uix/SocialNetwork/SocialNetwork";
import { FC } from "react";
import { LuMail } from "react-icons/lu";
import { MdPhoneForwarded } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa6";
import Link from "next/link";

const Footer: FC = () => {
  return (
    <div className='bottom-0'>
      <footer className='grid grid-cols-1 md:grid-cols-3 border-y border-y-primary/50 p-5 bg-gradient-to-tl from-primary/5'>
        <div className='text-center md:text-start'>
          <address className='space-y-2'>
            <p>RsTronic Martinique</p>
            <p>Entreprise indépendante</p>
            <p>RCS 408194884 Fort-de-France</p>
            <div>
              <a
                href='mailto:martinique@rstronic.com'
                className='inline-flex items-center justify-center md:justify-start text-primary'
              >
                <LuMail className='mr-2' /> martinique@rstronic.com
              </a>
            </div>
            <div>
              <a
                href='tel:+596696505653'
                className='inline-flex items-center justify-center md:justify-start text-primary'
              >
                <MdPhoneForwarded className='mr-2' />+ (596) 696505653
              </a>
            </div>
            <div>
              <a
                href='https://wa.me/596696505653?text=Pouvez+vous+m%27en+dire+plus'
                className='inline-flex items-center justify-center md:justify-start text-secondary'
              >
                <FaWhatsapp className='mr-2' />
                WhatsApp
              </a>
            </div>
          </address>
        </div>
        <div className='hidden md:block flex-1 self-center text-center mt-5 md:mt-0'>
          <ul>
            <li>Reprogrammation de Moteur</li>
            <li>Stage 1: Véhicule léger, Camion, Engins Agricole</li>
            <li>Suppression AD-BLUE</li>
            <li>Suppression FAP/ EGR</li>
            <li>Suppression Start and Stop</li>
          </ul>
          <div className='flex items-center justify-center mt-2 italic'>
            <p>&copy; 2024 RSTronic Martinique. All rights reserved.</p>
          </div>
        </div>
        <div className='hidden md:block flex-1 self-center text-center md:text-end mt-5 md:mt-0'>
          <ul className='space-y-2'>
            <li>Votre spécialiste de la reprogrammation moteur en Martinique</li>
            <li>
              <Link href='/mentions-legales' className='text-primary'>
                Mentions Légales
              </Link>
            </li>
            <li>
              <Link href='/conditions-generales-de-vente' className='text-primary'>
                Conditions générales de vente (CGV)
              </Link>
            </li>
            <li>
              <Link href='/politique-de-confidentialite' className='text-primary'>
                Politique de Confidentialité
              </Link>
            </li>
          </ul>
        </div>
      </footer>
      <div className='border-b-2 border-b-primary/60'>
        <SocialNetwork />
      </div>
    </div>
  );
};

export default Footer;
