"use client";

import Footer from "@/components/uix/Footer/Footer";
import ReprogrammationLineChart from "@/components/uix/Charts/ReprogrammationLineChart/ReprogrammationLineChart";
import Slider from "@/components/uix/Slide/Slider";
import TextPrimary from "@/components/uix/TextPrimary/TextPrimary";
import EngineRender from "@/components/uix/Home/EngineRender";
import ModulePresentation from "@/components/uix/Home/ModulePresentation";
import OffersInformation from "@/components/uix/renders/OffersInformation/OffersInformation";
import Image from "next/image";
import logo from "@/IMG/LOGO.png";
import Link from "next/link";
import { FaTachometerAlt } from "react-icons/fa";
import { FaGasPump, FaWrench } from "react-icons/fa6";
import CustomButton from "@/components/uix/CustomButton/CustomButton";

const images = [
  {
    src: "/images/slide/vehicle-1.jpg",
    alt: "Description de l'image 1",
    description: (
      <div>
        Plus de <TextPrimary text='COUPLE' /> Plus de <TextPrimary text='PUISSANCE' />
      </div>
    ),
  },
  {
    src: "/images/slide/vehicle-2.jpg",
    alt: "Description de l'image 2",
    description: (
      <div>
        <TextPrimary text='L&lsquo;essence' /> de la <TextPrimary text='PERFECTION' />
      </div>
    ),
  },
  {
    src: "/images/slide/vehicle-3.jpg",
    alt: "Description de l'image 3",
    description: (
      <div>
        Notre <TextPrimary text='Savoir Faire' /> à votre <TextPrimary text='service' />
      </div>
    ),
  },
];

const Home = () => {
  return (
    <>
      <div className='space-y-1'>
        <section className='shadow-md border-b border-b-primary/40'>
          <Slider images={images} />
        </section>

        <div className='space-y-1 px-1 md:px-2'>
          <section className='flex items-center justify-center bg-gradient-to-tr from-primary/15 shadow-md border border-primary/40'>
            <ModulePresentation />
          </section>

          <section className='grid grid-cols-1 md:grid-cols-2 gap-1 md:gap-2'>
            <article>
              <div className='flex flex-col gap-2 p-0 border border-primary/40 rounded bg-gradient-to-b from-primary/10 drop-shadow-[0_0_1px_rgba(200,200,200,0.25)]'>
                <ReprogrammationLineChart />
              </div>
            </article>
            <article className='flex items-center justify-between w-full h-auto bg-gradient-to-b from-primary/10 rounded border border-primary/40'>
              <EngineRender />
            </article>
          </section>

          <section className='flex flex-col items-center justify-center bg-gradient-to-b from-primary/15 border border-primary/40 p-0 rounded-md shadow-md'>
            <div className='flex-1'>
              <figure className='flex items-center h-[50px] w-[250px]'>
                <Image src={logo} alt='LOGO RSTronic Martinique' />
              </figure>
            </div>

            <header className='text-center'>
              <p className='text-md md:text-xl italic'>
                <strong>
                  Quand l&lsquo;innovation technologique se conjugue avec la passion dédiée à la
                  reprogrammation moteur en Martinique.
                </strong>
              </p>
              <h1 className='text-md md:text-xl'>
                Bienvenue chez <strong>Rstronic</strong> : Votre Référence en Optimisation et
                Reprogrammation Moteur en Martinique
              </h1>
            </header>

            <div className='text-justify space-y-2 p-4'>
              <p>
                Chez <strong>Rstronic</strong>, nous sommes spécialisés dans la reprogrammation
                de&nbsp;
                <strong>véhicules légers</strong>, <strong>poids lourds</strong>, et&nbsp;
                <strong>engins agricoles</strong>. Avec notre expertise, nous optimisons les&nbsp;
                <strong>performances moteur</strong>, garantissant une conduite fluide, économe et
                durable, tout en respectant les normes environnementales.
              </p>
              <p>
                Nous proposons des solutions sur mesure, adaptées à vos besoins :&nbsp;
                <strong>amélioration des performances</strong>,&nbsp;
                <strong>réduction de la consommation de carburant</strong> ou optimisation générale.
                Basés en <strong>Martinique</strong>, nous utilisons des outils de pointe afin
                garantir un travail de qualité.
              </p>
              <ul className='flex flex-col italic'>
                <li className='px-1 bg-primary/5 w-auto'>
                  <div className='flex items-center'>
                    <FaTachometerAlt className='inline-block mr-2' />
                    <p>
                      <strong>Performance optimisée :</strong> Une conduite dynamique et
                      performante.
                    </p>
                  </div>
                </li>
                <li className='px-1 bg-primary/5'>
                  <div className='flex items-center'>
                    <FaGasPump className='inline-block mr-2' />
                    <p>
                      <strong>Économie de carburant :</strong> Réduisez vos coûts tout en conservant
                      la puissance.
                    </p>
                  </div>
                </li>
                <li className='px-1 bg-primary/5'>
                  <div className='flex items-center'>
                    <FaWrench className='inline-block mr-2' />
                    <p>
                      <strong>Service de qualité :</strong> Une expertise et un soin apportés à
                      chaque projet.
                    </p>
                  </div>
                </li>
              </ul>
              <p>
                Nous travaillons avec des <strong>voitures particulières</strong>, des&nbsp;
                <strong>poids lourds</strong>, des <strong>tracteurs</strong> et autres&nbsp;
                <strong>véhicules agricoles</strong>. Faites confiance à <strong>Rstronic</strong>,
                votre partenaire pour exploiter le potentiel de votre véhicule en&nbsp;
                <strong>Martinique</strong> !
              </p>
              <p className='flex items-center justify-center'>
                Découvrez nos services et obtenez votre devis en sélectionnant votre véhicule et vos
                options de&nbsp;
                <Link href='/reprogrammation-moteur'> reprogrammation moteur</Link>.
              </p>
            </div>
          </section>

          <section className='flex items-center justify-center bg-gradient-to-br from-primary/5 border border-primary/40 shadow-md p-1'>
            <div className='flex items-center gap-1'>
              <Link href='/reprogrammation-moteur'>
                <CustomButton variant='default'>Reprogrammation</CustomButton>
              </Link>
              <Link href='/contact'>
                <CustomButton variant='default'>Contact</CustomButton>
              </Link>
            </div>
          </section>

          <section>
            <div className='mb-1'>
              <OffersInformation />
            </div>
          </section>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Home;
