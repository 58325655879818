"use client";

import { FC, ReactNode } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import Image from "next/image";

import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import "swiper/css/navigation";

interface TSliderProps {
  images: { src: string; alt: string; description: ReactNode }[];
}

const Slider: FC<TSliderProps> = ({ images }: TSliderProps) => {
  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      loop={true}
      autoplay={{ delay: 5000 }}
      pagination={{ clickable: true }}
      modules={[Autoplay, Pagination]}
    >
      {images.map((image, index) => (
        <SwiperSlide key={index}>
          <div className='relative w-full h-[200px] sm:h-[300px] md:h-[400px] lg:h-[450px]'>
            <Image src={image.src} alt={image.alt} fill className='object-cover' />

            <div className='absolute inset-0 flex items-center justify-center'>
              <div className='bg-black bg-opacity-50 text-white text-lg md:text-2xl p-2 rounded font-bold uppercase'>
                {image.description}
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slider;
