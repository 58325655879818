"use client";

import { FC, ReactNode } from "react";
import AccordionOfferInformation from "@/components/uix/Accordion/AccordionOfferInformation/AccordionOfferInformation";
import { GrValidate } from "react-icons/gr";
import StageVL from "@/IMG/entreprise/RS-VL.png";
import StagePL from "@/IMG/entreprise/RS-PL.png";
import StageAGRI from "@/IMG/entreprise/RS-AGRI.png";

interface TOffersInformationProps {}

const OffersInformation: FC<TOffersInformationProps> = (props: TOffersInformationProps) => {
  const stageVL = (): ReactNode => {
    return (
      <p className='text-justify'>
        Il est évident que la reprogrammation moteur (stage 1), apporte de suite des gains à votre
        moteur. Elle exploite au mieux le potentiel et les réglages des données informatiques
        programmer dans votre calculateur moteur , la modification des valeurs, limiteur, consigne,
        sollicitera moins votre la mécaniques après reprogrammation de celui-ci , pour la
        reprogrammation véhicule utilitaire plus besoins de descendre dans les rapports « le couple
        « est enfin là où on en a besoin.
        <br />
        <br />A conduite égale, votre voiture reprogrammée n’est plus en sous régime, elle force
        moins, à conduite plus sportive vous bénéficiez de plus chevaux et un couple bien plus
        prononcé.
      </p>
    );
  };

  const stagePL = () => {
    return (
      <div>
        <div>
          <ul>
            <li className='flex items-center gap-2 text-primary mb-1 px-1 bg-foreground/5'>
              <GrValidate className='text-secondary' />
              <p>+ 20% de Couple</p>
            </li>
            <li className='flex items-center gap-2 text-primary mb-1 px-1 bg-foreground/5'>
              <GrValidate className='text-secondary' />
              <p>+ 20% de Puissance</p>
            </li>
            <li className='flex items-center gap-2 text-primary mb-1 px-1 bg-foreground/5'>
              <GrValidate className='text-secondary' />
              <p>- 5% à - 15% de Carburant</p>
            </li>
          </ul>
        </div>
        <p className='text-justify'>
          A conduite normale, la consommation de carburant de votre camion sera beaucoup plus faible
          après la reprogrammation camion. En raison d’un petit décalage de la plage d’utilisation
          du couple il sera à un plus bas régime .
          <br />
          <br />
          Cela conduit à une diminution de la consommation de carburant.
        </p>
      </div>
    );
  };

  const stageAGRI = () => {
    return (
      <div>
        <div>
          <ul>
            <li className='flex items-center gap-2 text-primary mb-1 px-1 bg-foreground/5'>
              <GrValidate className='text-secondary' />
              <p>+ 20% de Couple</p>
            </li>
            <li className='flex items-center gap-2 text-primary mb-1 px-1 bg-foreground/5'>
              <GrValidate className='text-secondary' />
              <p>+ 20% de Puissance</p>
            </li>
            <li className='flex items-center gap-2 text-primary mb-1 px-1 bg-foreground/5'>
              <GrValidate className='text-secondary' />
              <p>- 5% à - 15% de Carburant</p>
            </li>
          </ul>
        </div>
        <p className='text-justify'>
          Gagner en couple et puissance et bien sûr également en baisse de consommation de manière
          significative. Nos reprogrammations sont toutes effectuées au cas par cas c’est à dire que
          nous travaillons uniquement sur le programme d’origine de la gestion moteur extrait que ce
          soit en passant via la prise OBD ou en se connectant directement au calculateur moteur .
        </p>
      </div>
    );
  };

  return (
    <div className='grid grid-cols-1 md:grid-cols-3 gap-1 md:gap-2'>
      <div className='min-h-auto overflow-hidden'>
        <AccordionOfferInformation
          AccordionItemValue='vl'
          title='Stage 1 VL'
          image={StageVL}
          text={stageVL()}
        />
      </div>
      <div className='min-h-auto overflow-hidden'>
        <AccordionOfferInformation
          AccordionItemValue='pl'
          title='Stage 1 Camion'
          image={StagePL}
          text={stagePL()}
        />
      </div>
      <div className='min-h-auto overflow-hidden'>
        <AccordionOfferInformation
          AccordionItemValue='agri'
          title='Stage 1 Agricole'
          image={StageAGRI}
          text={stageAGRI()}
        />
      </div>
    </div>
  );
};

export default OffersInformation;
