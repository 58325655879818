import { FC, ReactNode } from "react";
import Link from "next/link";
import ModuleRotate from "@/components/effects/ModuleRotate";
import { BsTwitterX } from "react-icons/bs";
import { FaMeta, FaWhatsapp } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";

type LinkItem = {
  title: string;
  href: string;
  icon: ReactNode;
};

const links: LinkItem[] = [
  { title: "Twitter", href: "https://twitter.com/rstronic_972", icon: <BsTwitterX size={18} /> },
  {
    title: "Meta",
    href: "https://www.facebook.com/rstronic.martinique",
    icon: <FaMeta size={18} />,
  },
  {
    title: "Instagram",
    href: "https://www.instagram.com/rstronic_martinique/?hl=fr",
    icon: <FaInstagram size={18} />,
  },
  {
    title: "WhatsApp",
    href: "https://wa.me/596696505653?text=Pouvez+vous+m%27en+dire+plus",
    icon: <FaWhatsapp size={18} />,
  },
];

const SocialNetwork: FC = () => {
  return (
    <ul className='flex justify-center items-center gap-2 p-2 text-primary'>
      {links &&
        links.map((link: LinkItem) => {
          return (
            <li key={link.title}>
              <ModuleRotate>
                <Link target='_blank' href={`${link.href}`} aria-label={`Lien vers ${link.title}`}>
                  {link.icon}
                </Link>
              </ModuleRotate>
            </li>
          );
        })}
    </ul>
  );
};

export default SocialNetwork;
