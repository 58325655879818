import { forwardRef, ReactNode } from "react";
import { Button } from "@/components/shadcn/ui/button";
import { cn } from "@/lib/utils";

type CustomButtonProps = {
  children: ReactNode;
  onClick?: () => void;
  variant:
    | "secondary"
    | "default"
    | "destructive"
    | "outline"
    | "ghost"
    | "link"
    | null
    | undefined;
  className?: string;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
};

const CustomButton = forwardRef<HTMLButtonElement, CustomButtonProps>(function CustomButton(
  { variant, className, type, disabled, children, onClick },
  ref,
) {
  return (
    <Button
      ref={ref}
      type={type}
      variant={variant}
      className={cn(className)}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
});

export default CustomButton;
