"use client";

import { FC, ReactNode, useState } from "react";

type TModuleRotateProps = {
  children: ReactNode;
};

const ModuleRotate: FC<TModuleRotateProps> = ({ children }: TModuleRotateProps) => {
  const [isRotated, setIsRotated] = useState(false);

  return (
    <div
      className={`transition-transform transform-gpu ${isRotated ? "rotate-180" : "rotate-0"}`}
      onMouseEnter={() => setIsRotated(true)}
      onMouseLeave={() => setIsRotated(false)}
    >
      {children}
    </div>
  );
};

export default ModuleRotate;
